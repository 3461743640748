import { Helmet } from "react-helmet";
import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
// eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router";
import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { edgesToArray, buildSEOProductObject, isBrowser } from "../../context/helpers";
import { HiddenElementsContext } from "../../context/HiddenElements";
import { populateBaseProducts, fillPrices, reorderSolidGold } from "../../context/helpers_plp";
import { getGalleryImages } from "../../helpers/product";
import { useCurrentLang } from "../../hooks/useLocalizedCartInfo";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import {
  usePricesByCollectionLocal,
  usePriceLocalInfo,
  usePriceInfo,
  useCurrentCountryCode,
} from "../../hooks/usePrices";
import useCheckInventory from "../../hooks/useCheckInventory";

import ALTag from "../../components/al_components/ALTag";
import GalleryMini from "../../components/GalleryMini";
import ProductSummary from "../../components/ProductPage/ProductSummary";
import PdpAdsCollection from "./PdpAdsCollection";
import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";
import ProductDetails from "../../components/ProductPage/Details/ProductDetails";
import ReviewsPopup from "../../components/ReviewsPopup";
import { ComponentType } from "../../constants/ComponentType";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";

import * as Styles from "./lp_pdp.module.scss";

const FloatingAddToCart = loadable(() => import("../../components/AddToCart/FloatingAddToCart"));

function LandingProductPage({ data, pageContext }) {
  const lang = useCurrentLang();
  const countryCode = useCurrentCountryCode();
  const location = useLocation();
  const { isMobile } = useDeviceDetect();
  const { throwReport } = useALError();
  const { hide, show } = useContext(HiddenElementsContext);

  const [isReviewsPopupOpen, setIsReviewsPopupOpen] = useState(false);
  const [userChanged, setUserChanged] = useState(false);

  const { variants: productVariantsInventory } = useCheckInventory(data.shopifyProduct.handle);
  const preOrderData = useMemo(
    () =>
      data.preOrder.edges.length > 0
        ? data.preOrder.edges.filter((p) => p.node.handle === data.shopifyProduct?.handle)[0].node
        : null,
    [data.preOrder, data.shopifyProduct]
  );

  const productData = useMemo(() => {
    if (productVariantsInventory && productVariantsInventory.length > 0) {
      data.shopifyProduct.variants = data.shopifyProduct.variants.map((v) => {
        const variantInventory = productVariantsInventory.find((p) => p.id === v.shopifyId);
        v.availableForSale = variantInventory?.availableForSale;
        return v;
      });
    }

    return {
      ...(data.shopifyProduct || {}),
      ...(data.contentfulProductPage || {}),
      prices: edgesToArray(data.allShopifyPrices),
      preOrder: preOrderData,
    };
  }, [data, preOrderData]);

  const isGiftCard = productData.handle === HandleType.GIFT_CARD;
  const isSolidGold = productData.tags.includes("Components:Solid Gold");
  const productType = productData.type[0];
  const firstAvailableVariant = productData.variants.find((variant) => variant.availableForSale);

  const productsInCollectionOriginal = useMemo(() => {
    const originalCollectionProducts = populateBaseProducts(
      data,
      { collectionProductHandles: pageContext.allProductsHandles },
      lang
    );
    return isSolidGold
      ? reorderSolidGold(originalCollectionProducts, productType)
      : originalCollectionProducts;
  }, [data, pageContext, lang, isSolidGold, productType]);

  const [variantSelectedOptions, setVariantSelectedOptions] = useState({
    [firstAvailableVariant?.selectedOptions?.[0]?.name]:
      firstAvailableVariant?.selectedOptions?.[0]?.value,
  });

  const selectedVariant =
    variantSelectedOptions &&
    productData.variants?.find(
      (x) => x.title === variantSelectedOptions[Object.keys(variantSelectedOptions)[0]]
    );

  const productPrice = data?.allShopifyPrices?.edges.find(
    (p) => p?.node?.country === countryCode
  )?.node;

  const priceInfo = usePriceLocalInfo({
    handle: productData.handle,
    price: productPrice,
    selectedVariant,
    tags: { [productData.handle]: productData.tags },
  });

  const [{ data: fetchedPrices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [productData.handle],
    tags: { [productData.handle]: productData.tags },
    shouldFetchPrice: !priceInfo,
  });

  const prices = useMemo(() => fetchedPrices || priceInfo, [priceInfo, fetchedPrices]);

  const productJsonLd = useMemo(() => {
    try {
      if (isBrowser && productData) {
        let category = "Jewelry";
        if (productData.type?.[0]) {
          category += ` > ${productData.type[0]}`;
        }
        if (productData.subtype?.[0]) {
          category += ` > ${productData.subtype[0]}`;
        }

        const productForSeo = {
          title: productData.title,
          description:
            productData.seoContent?.description?.description || productData.description.description,
          images: productData.images,
          variants: productData.variants,
          handle: productData.handle,
          isPreOrder: productData.isPreOrder,
          productDetails: productData.productDetails,
          category,
          prices,
        };
        return buildSEOProductObject(productForSeo);
      }
    } catch (error) {
      throwReport(error);
    }
    return null;
  }, [productData, prices, throwReport]);

  useEffect(() => {
    // Check variant availability and set the selected option to the first available variant
    if (!userChanged && productVariantsInventory) {
      productVariantsInventory.every((variantInventory) => {
        const productVariant = productData.variants.find((e) => e.id === variantInventory.id);
        if (variantInventory.availableForSale && productVariant.availableForSale) {
          setVariantSelectedOptions({ [productData.options[0].name]: variantInventory.title });
          return false;
        }
        return true;
      });
    }
  }, [productData, productVariantsInventory, userChanged]);

  const isByobType = data?.collection?.type === "build-your-own-bundle";

  const productsPrices = usePricesByCollectionLocal({
    productHandlesInCollection: productsInCollectionOriginal.map((p) => p.node.handle) || [],
    prices: data?.shopifyPrices?.edges?.filter((p) => p?.node?.country === countryCode),
    productsTags: productsInCollectionOriginal.reduce(
      (acc, p) => ({ ...acc, [p.node.handle]: p.node.tags }),
      {}
    ),
    shouldUseDiscount: !isByobType,
  });

  const productsInCollection = fillPrices(productsInCollectionOriginal, productsPrices);

  const galleryImages = useMemo(
    () => getGalleryImages(productData, variantSelectedOptions),
    [productData, variantSelectedOptions]
  );

  const isUS = countryCode === Country.US;
  const showFloatingATC = !(!isMobile || (!isUS && isGiftCard));

  const selectedVariantInventory = productVariantsInventory.find(
    (v) => v?.sku === selectedVariant?.sku
  );
  if (selectedVariantInventory) {
    // Set recent availability from check-inventory
    selectedVariant.availableForSale = selectedVariantInventory.availableForSale;
  }

  const handleVariantChange = (event) => {
    const { target } = event;
    setUserChanged(true);
    setVariantSelectedOptions((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  useEffect(() => {
    hide();
    return () => {
      show();
    };
  }, [hide, show]);

  const onReviewsClick = useCallback(() => {
    setIsReviewsPopupOpen(true);
  }, []);

  return (
    <div className={Styles.container}>
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com/products/${productData.handle}/`} />
        {productJsonLd && (
          <script type="application/ld+json">{JSON.stringify(productJsonLd)}</script>
        )}
      </Helmet>
      <div className={Styles.content}>
        {/* Product */}
        <div className={Styles.productContainer}>
          <GalleryMini
            images={galleryImages}
            videoId={productData.videoId || null}
            video={productData.video}
          />
          <ALTag
            product={{ contentful: { node: productData.contentful }, node: productData }}
            componentType={ComponentType.PDP_ADS}
            priceInfo={prices}
          />

          <div className={Styles.productInfo}>
            <ProductSummary
              product={productData}
              countryCode={countryCode}
              selectedVariant={selectedVariant}
              variantSelectedOptions={variantSelectedOptions}
              componentType={ComponentType.PDP_ADS}
              onVariantOptionChange={handleVariantChange}
              price={productPrice}
              onReviewsClick={onReviewsClick}
              prices={prices}
              pricesLoading={pricesLoading}
            />

            <ProductDetails
              tags={productData.tags}
              productHandle={productData.handle}
              isBundle={isByobType}
              countryCode={countryCode}
              details={productData.productDetails}
              description={productData.description?.description || ""}
              title={productData.title}
              componentType={ComponentType.PDP_ADS}
              expandableDetails
            />
          </div>
        </div>

        <ReviewsPopup
          open={isReviewsPopupOpen}
          productHandle={productData.handle}
          productId={productData.shopifyId.replace("gid://shopify/Product/", "")}
          productType={productData.type[0]}
          onClose={() => setIsReviewsPopupOpen(false)}
        />

        {/* Collection  */}
        {data.collection && (
          <PdpAdsCollection
            productsInCollection={productsInCollection}
            productData={productData}
            collectionHandle={data.collectionHandle}
            shopifyPrices={data.shopifyPrices}
            collection={data.collection}
          />
        )}

        {data.allContentfulLandingPage?.edges[0]?.node && (
          <div className={Styles.lpBlocks}>
            <ALCustomLandingPage
              data={data.allContentfulLandingPage.edges[0].node}
              componentType={ComponentType.PDP}
            />
          </div>
        )}
      </div>
      {showFloatingATC && (
        <FloatingAddToCart
          product={{
            ...data.shopifyProduct,
            ...data.contentfulProductPage,
          }}
          selectedVariant={selectedVariant}
          variantSelectedOptions={variantSelectedOptions}
          countryCode={countryCode}
          searchObject={location?.state?.searchObject}
          prices={prices}
          componentType={ComponentType.PDP_ADS}
        />
      )}
    </div>
  );
}

LandingProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default withALErrorBoundary({
  name: "LandingProductPage",
  priority: "P1",
})(LandingProductPage);

export const query = graphql`
  query (
    $id: String!
    $allProductsHandles: [String]!
    $collectionHandle: String!
    $shopifyStore: String!
    $lang: String!
  ) {
    allShopifyPrices(filter: { handle: { eq: $id } }) {
      edges {
        node {
          id
          country
          shopifyId
          handle
          variants {
            id
            priceV2: price {
              amount
              currencyCode
            }
            compareAtPriceV2: compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $id }, storeName: { eq: $shopifyStore }) {
      id
      title
      handle
      createdAt(fromNow: true)
      publishedAt
      productType
      tags
      shopifyId
      variants {
        availableForSale
        inventoryPolicy
        id
        title
        sku
        shopifyId
        selectedOptions {
          name
          value
        }
        image {
          url
          altText
        }
      }
      options {
        id
        name
        values
      }
      images {
        url
        altText
      }
    }
    preOrder: allContentfulProductsPreOrderProduct(
      filter: { handle: { eq: $id }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          handle
          shippingDate
          startDate
          endDate
        }
      }
    }
    contentfulProductPage(handle: { eq: $id }, node_locale: { eq: $lang }) {
      ...contentfulFields
    }
    allShopifyProduct(
      filter: { handle: { in: $allProductsHandles }, storeName: { eq: $shopifyStore } }
    ) {
      nodes {
        id
        title
        handle
        createdAt(fromNow: true)
        publishedAt
        productType
        tags
        shopifyId
        variants {
          availableForSale
          inventoryPolicy
          id
          title
          sku
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        images {
          url
          altText
        }
      }
    }
    allContentfulProductPage(
      filter: { handle: { in: $allProductsHandles }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          ...contentfulFields
        }
      }
    }
    shopifyPrices: allShopifyPrices(filter: { handle: { in: $allProductsHandles } }) {
      edges {
        node {
          id
          country
          shopifyId
          handle
          variants {
            id
            priceV2: price {
              amount
              currencyCode
            }
            compareAtPriceV2: compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    collection: contentfulCollectionPage(
      handle: { eq: $collectionHandle }
      node_locale: { eq: $lang }
    ) {
      handle
      title
      type
      description {
        description
      }
      rowsPerPage
      hasCard
      cardsContainer {
        title
        cards {
          title
          subtitle
          discountCode
          backgroundColor
          textColor
          videoId
          link {
            title
            link {
              link
            }
          }
          images {
            altText
            desktopImage {
              file {
                url
              }
            }
            mobileImage {
              file {
                url
              }
            }
          }
        }
      }
      cards {
        title
        subtitle
        backgroundColor
        textColor
        videoId
        link {
          title
          link {
            link
          }
        }
      }
    }
    allContentfulLandingPage(
      filter: { handle: { eq: "__ADS_PDP__" }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          blocks {
            ...ContentfulLandingPageBlocks
          }
        }
      }
    }
  }
`;
